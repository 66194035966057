.clientcard{
    h5{
        font-size: 17px;
     }
     p{
        font-size: 10px; /* Adjust the font size as needed */
        margin: 0;
      }
}

.checked {
    color: #ffa500;
  }

  .clientcardradius{
    border-radius: 15px;
  }

  .textCard{
    font-size:14px
  }
  .cardLabels{
    font-family: 'Inter';
  }