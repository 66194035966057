/* .bgaboutDiv {
    background-image: url('../../assests/images/bg3dark.png'),url('../../assests/images/bg1.png');
    background-repeat: no-repeat;
    background-position: right bottom,left top; 
    background-position-x: 0%, 0%;
    background-position-y: 0%, 0%; 
    background-attachment: fixed;
    background-position: 0% 0%, 0% 0%;
    background-size: 50%,50%;
 }
 .bgaboutDivLight {
    background-image: url('../../assests/images/bg3.png'),url('../../assests/images/bg2.png');
    background-repeat: no-repeat;
    background-position: right bottom,left top;
    background-size: 761px 614px;
 } */

 .bgaboutDivLight {
   background-image: url('../../assests/images/bg2.png');
   background-repeat: no-repeat;
   background-size: 55%;
   background-position: -20%,0%;
   
} 
.bgaboutDiv {
   background-image:url('../../assests/images/bg1.png');
   background-repeat: no-repeat;
   background-position: right bottom,left top;
   background-size: 55%;
   background-position: -20%,0%; 
   
}
 