

.statuscard{
 h5{
    font-size: 16px;
 }
 p{
    font-size: 10px !important;
 }
}

.bgStreeDiv {
   background-image: url('../../assests/images/bg3dark.png');
   background-repeat: no-repeat;
   background-position: 50%,50%;
  
   background-size: 60%;
}
.bgStreeDivLight {
   background-image: url('../../assests/images/bg3.png');
   background-repeat: no-repeat;
   background-position: 50%,50%;
   background-size: 60%;
}
.paraLineheight{
   line-height: 30px;
}
.alignLeft{
   padding-left: 10%;
   /* padding-top: 12px; */
}