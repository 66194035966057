
.footerDiv{
    background-color: #e4e3e1 !important;
    color:#000;
    font-family: 'Poppins';
    p{
        font-size: 15px;
    }
}
.footerDark{
    background: #252525;
    color:#fff;
    p{
        font-size: 15px;
    }
}
.footerlist{
    padding: 2% 8% 0;
    a{
    color: #767F8C;
    font-size: 24px;
    text-decoration: none;
    }
}
.hrLine{
    border-top: 1px solid #4C4C4C;
    margin: 1% 5% 2%;
}
.footerights{
    color: #767F8C;
}
.plabel{
    color: #3968B2;
}
.footerLabel1{
    padding: 2% 0% 0 10%;
}
.footerlabel2{
    padding: 2% 0 2% 8%;
}