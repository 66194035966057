.teamcard{
 h5{
    font-size: 17px;
 }
 p:nth-of-type(1) {
    font-size: 14px; /* Adjust the font size as needed */
    font-weight: 500;
    margin: 0;
  }
 p:nth-of-type(2) {
    font-size: 12px; /* Adjust the font size as needed */
    margin: 0;
  }
}
.cardLabels{
  font-family: 'Inter';
}
.cardpara{
  font-size: 14px;
}

.linkedinbutton{
    padding: 3px 19px;
    border-radius: 4px;
    background-color: transparent;
    color: rgb(0, 140, 255);
    border: 1px solid #d9d4d4;
}
.cardRadius{
    border-radius: 12px;
}