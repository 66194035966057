.darkbutton{
    border: 1px solid black;
    width: 20px;
    height: 20px;
    border-radius: 24px;
    background-color: #000;
}

.lightbutton{
    border: 1px solid #E9E9E9;
    width: 20px;
    height: 20px;
    border-radius: 24px;
    background-color:#E9E9E9;
}
.contentPadding{
    padding-top: 40px;
}

.searchIcon{
    border: 0;
    border-radius: 20px 0px 0px 20px;
    background-color: #939393;
    color: #fff;
}
.searchInput{
    width: 56%;
}

.navDarkbgColor{
    background-color: #101010;
}

.searchInputField{
    border: none;
    background-color:#939393;
    border-radius: 0px 20px 20px 0px;
    
    
}
.searchInputField:focus{
    outline: none;

}
.form-control:focus {
    outline: none;
}
.searchInputField::placeholder {
    color: #fff; /* Change this to your desired color */
  }
.font{
    font-family: 'Poppins';
}
.teambg{
    background-image: url('../../assests/images/bg3.png');
    background-repeat: no-repeat;
    background-size: 40%;
    background-position: 85%,0%;
  }
  
  .teambgDark{
    background-image: url('../../assests/images/bg3dark.png');
    background-repeat: no-repeat;
    background-size: 40%;
    background-position: 85%,0%;
  }