
.containerDiv{
    margin: 2% 8%;
   
    p{
        font-size: 26px;
    }
}
.buttonprimary{
    padding: 8px 18px;
    border-radius: 26px;
    background-color: transparent;
    color:#000;
    border: 1px solid #000;

}
.buttonprimary:hover {
    background-color: #000;
    color: #fff; /* Change text color to white on hover */
}
.dark{
    padding: 8px 18px;
    border-radius: 26px;
    background-color: transparent;
    color:#fff;
    border: 1px solid #fff;
}
.dark:hover {
    background-color: #fff;
    color:  #000; /* Change text color to white on hover */
}

    .bgDiv {
        background-image: url('../../assests/images/bg1.png');
        background-repeat: no-repeat;
        background-size: 45%;
        background-position: -10%,30%;
    }
    .bgDivLight {
        background-image: url('../../assests/images/bg2.png');
        background-repeat: no-repeat;
        background-size: 45%;
        background-position: -10%,30%;
    }
.alignTop{
    margin-top: 10%;
}